<template>
  <div class='page promotions'>
    
    <CRow>
      <CCol md='12'>
        <div class='promo-large'>
          <img src='https://cdn.s777.club/web/promo-banner-large.png' class='banner'/>
          <!--<img src='https://cdn.s777.club/web/promo-banner-large-halloween.png' class='banner'/>-->
          <!--<img src='https://cdn.s777.club/web/promo-banner-large-christmas.png' class='banner'/>-->
          <div class='promo-info'>
            <span class='promo-tagline'>Super Awesome Games</span>
            <h2 class='promo-title'>Super777's Awesome Offer</h2>
            <h4 class='promo-subtitle'>Complete your profile & instantly get an extra $10 bonus on your next deposit (min. deposit $10)</h4>
            <p class='promo-summary'>Simply log in to your account, complete your profile (name and mobile number), make a deposit to activate your bonus. The result? Maximum winning potential!</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
      <CCol md='6' sm='12'>
        <div class='promo-regular'>
          <img src='https://cdn.s777.club/web/promo-banner1.png' class='img-fluid w-100'/>
          <div class='promo-info'>
            <span class='promo-tagline'>Adventure is just around the corner</span>
            <h2 class='promo-title'>1st Deposit Bonus Up to $100</h2>
            <h4 class='promo-subtitle'>Get an additional 50% on top of your first deposit (min. deposit $10)</h4>
            <p class='promo-summary'>All you have to do is register and then make a deposit. Afterwards - simply enjoy the game</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
      <CCol md='6' sm='12'>
        <div class='promo-regular'>
          <img src='https://cdn.s777.club/web/promo-banner2.png' class='img-fluid w-100'/>
          <div class='promo-info'>
            <span class='promo-tagline'>Time to play Big</span>
            <h2 class='promo-title'>High Roller Bonus Up to $250</h2>
            <h4 class='promo-subtitle'>Get an additional 80% on top of your first deposit (min. deposit $250)</h4>
            <p class='promo-summary'>This bonus is for adventurous thrill seekers, for whom our standard Welcome Bonus isn't quite enough.</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
      <CCol md='6' sm='12'>
        <div class='promo-regular'>
          <img src='https://cdn.s777.club/web/promo-banner3.png' class='img-fluid w-100'/>
          <div class='promo-info'>
            <span class='promo-tagline'>Luck is when opportunity meets preparation.</span>
            <h2 class='promo-title'>All-Day Reload Bonus ++ (Bitcoin)</h2>
            <h4 class='promo-subtitle'>Get 25% additional credits (min. deposit $100)</h4>
            <p class='promo-summary'>Don't sit around twiddling your thumbs, play your favorite games and get bonuses!</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
      <CCol md='6' sm='12'>
        <div class='promo-regular'>
          <img src='https://cdn.s777.club/web/promo-banner4.png' class='img-fluid w-100'/>
          <div class='promo-info'>
            <span class='promo-tagline'>Bonuses everywhere, ripe for the taking!</span>
            <h2 class='promo-title'>All-Day Reload Bonus (Bitcoin)</h2>
            <h4 class='promo-subtitle'>Get 15% additional credits (min. deposit $20)</h4>
            <p class='promo-summary'>Get ready for a great opportunity to strike gold! What are you waiting for? Get your game on now!</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
      <CCol md='6' sm='12'>
        <div class='promo-regular'>
          <img src='https://cdn.s777.club/web/promo-banner5.png' class='img-fluid w-100'/>
          <div class='promo-info'>
            <span class='promo-tagline'>Luck is believing you’re lucky.</span>
            <h2 class='promo-title'>All-Day Reload Bonus ++ (Cashapp)</h2>
            <h4 class='promo-subtitle'>Get 15% additional credits (min. deposit $100)</h4>
            <p class='promo-summary'>Enjoy some big payouts don't forget to thank us on the way out!</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
      <CCol md='6' sm='12'>
        <div class='promo-regular'>
          <img src='https://cdn.s777.club/web/promo-banner6.png' class='img-fluid w-100'/>
          <div class='promo-info'>
            <span class='promo-tagline'>Better an ounce of luck than a pound of gold.</span>
            <h2 class='promo-title'>All-Day Reload Bonus (Cashapp)</h2>
            <h4 class='promo-subtitle'>Get 10% additional credits (min. deposit $20)</h4>
            <p class='promo-summary'>A path fraught with fortune - and all you need to do is play!</p>
            <div class='promo-actions'>
              <CButton shape='pill' size='lg' class='home-signup' @click='openSignupModal' v-if='!isLoggedIn'>Signup</CButton>
              <CButton shape='pill' size='lg' class='home-signup' @click='openDepositModal' v-if='isLoggedIn'>Deposit</CButton>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js'

export default {
  name: 'Promotions',
  computed: {
    isLoggedIn () {
      return this.$store.state.isLoggedIn
    }
  },
  methods: {
    openSignupModal(){
      EventBus.$emit('openSignupModal')
    },
    openDepositModal(){
      EventBus.$emit('openDepositModal')
    }
  }
}
</script>
